import { ApplicationStatus } from '../../types';
import { CreateActionParams } from '../actions';
import { getUserInfo } from './implementations/getUserInfo';
import { createUpcomingDummyState } from '../../utils/createDummyState';
import { EventNames } from '../../core/fedops';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  context,
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const { fedopsLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.WidgetInitialized);

    const [state, setState] = getControllerState();

    if (state.environmentModes.isEditorMode) {
      return setState(createUpcomingDummyState(context));
    }

    const userInfo = getUserInfo(context);

    setState({
      status: ApplicationStatus.IDLE,
      userInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.WidgetInitialized);
  };
}
