import { defaultLocale } from '../../../../consts/locale';
import { ActionsContext } from '../../../../core/actionsContext/contextFactory';
import { UserInfo } from '../../../../types';

export const getUserInfo = ({ flowAPI }: ActionsContext): UserInfo => {
  const locale =
    flowAPI?.controllerConfig?.wixCodeApi?.window?.locale || defaultLocale;
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  return { locale, timezone };
};
