import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.HistoryTabLoaded);

    const [state, setState] = getControllerState();

    const { bookings, selectedTimezone, businessInfo } =
      await api.getEnrichedBookings({
        type: TabTypes.HISTORY,
        applicationState: state,
      });

    api.logTabLoadedBi({ tabName: TabTypes.HISTORY, data: bookings, biLogger });

    setState({
      historyData: bookings,
      selectedTimezone,
      businessInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.HistoryTabLoaded);
  };
}
