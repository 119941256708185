import { UserInfo } from '../../src/types';

export const mockUserInfo = ({
  locale = 'he-il',
  timezone = 'America/Mexico_City',
}: Partial<UserInfo> = {}): UserInfo => {
  return {
    locale,
    timezone,
  };
};
