import { ActionFactoryParams } from '../core/ControlledComponent/types';
import { ApplicationState } from '../types';
import { ActionsContext } from '../core/actionsContext/contextFactory';
import { createGetUpcomingAction, GetUpcoming } from './getUpcoming';
import {
  InitializeWidget,
  createInitializeWidgetAction,
} from './initializeWidget';
import { createGetHistoryAction, GetHistory } from './getHistory';
import {
  createSetSelectedTimezoneAction,
  SetSelectedTimezone,
} from './setSelectedTimezone';
import { createSetSelectedTabAction, SetSelectedTab } from './setSelectedTab';
import {
  ClickOnEmptyStateButton,
  createClickOnEmptyStateButtonAction,
} from './clickOnEmptyStateButton';
import {
  ClickOnBookAgain,
  createClickOnBookAgainAction,
} from './clickOnBookAgain';
import { createShowModalAction, ShowModal } from './showModal';
import { createCloseModalAction, CloseModal } from './closeModal';
import { createCloseToastAction, CloseToast } from './closeToast';
import { createShowToastAction, ShowToast } from './showToast';
import { ClickOnCancel, createClickOnCancelAction } from './clickOnCancel';
import {
  ClickOnReschedule,
  createClickOnRescheduleAction,
} from './clickOnReschedule';
import {
  createClickOnViewMoreDetailsAction,
  ClickOnViewMoreDetails,
} from './clickOnViewMoreDetails';
import { createOnConfigUpdateAction, OnConfigUpdate } from './onConfigUpdate';
import {
  createClickOnZoomLinkAction,
  ClickOnZoomLink,
} from './clickOnZoomLink';
import {
  GetCancellationFeeInfo,
  createGetCancellationFeeInfoAction,
} from './getCancellationFeeInfo';
import {
  createDeleteCancellationFeeInfoAction,
  DeleteCancellationFeeInfo,
} from './deleteCancellationFeeInfo';

export type CreateActionParams = ActionFactoryParams<
  ApplicationState,
  ActionsContext
>;

export type ControllerActions = {
  initializeWidget: InitializeWidget;
  getUpcoming: GetUpcoming;
  getHistory: GetHistory;
  setSelectedTimezone: SetSelectedTimezone;
  setSelectedTab: SetSelectedTab;
  clickOnEmptyStateButton: ClickOnEmptyStateButton;
  clickOnBookAgain: ClickOnBookAgain;
  clickOnReschedule: ClickOnReschedule;
  clickOnViewMoreDetails: ClickOnViewMoreDetails;
  showModal: ShowModal;
  closeModal: CloseModal;
  showToast: ShowToast;
  closeToast: CloseToast;
  clickOnCancel: ClickOnCancel;
  onConfigUpdate: OnConfigUpdate;
  clickOnZoomLink: ClickOnZoomLink;
  deleteCancellationFeeInfo: DeleteCancellationFeeInfo;
  getCancellationFeeInfo: GetCancellationFeeInfo;
};

export const createActions = (
  actionFactoryParams: ActionFactoryParams<ApplicationState, ActionsContext>,
): ControllerActions => {
  const actions = {
    initializeWidget: createInitializeWidgetAction({ ...actionFactoryParams }),
    getUpcoming: createGetUpcomingAction({ ...actionFactoryParams }),
    getHistory: createGetHistoryAction({ ...actionFactoryParams }),
    setSelectedTimezone: createSetSelectedTimezoneAction({
      ...actionFactoryParams,
    }),
    setSelectedTab: createSetSelectedTabAction({ ...actionFactoryParams }),
    clickOnEmptyStateButton: createClickOnEmptyStateButtonAction({
      ...actionFactoryParams,
    }),
    clickOnBookAgain: createClickOnBookAgainAction({
      ...actionFactoryParams,
    }),
    clickOnReschedule: createClickOnRescheduleAction({
      ...actionFactoryParams,
    }),
    clickOnViewMoreDetails: createClickOnViewMoreDetailsAction({
      ...actionFactoryParams,
    }),
    clickOnCancel: createClickOnCancelAction({ ...actionFactoryParams }),
    showModal: createShowModalAction({ ...actionFactoryParams }),
    closeModal: createCloseModalAction({ ...actionFactoryParams }),
    showToast: createShowToastAction({ ...actionFactoryParams }),
    closeToast: createCloseToastAction({ ...actionFactoryParams }),
    onConfigUpdate: createOnConfigUpdateAction({ ...actionFactoryParams }),
    clickOnZoomLink: createClickOnZoomLinkAction({ ...actionFactoryParams }),
    deleteCancellationFeeInfo: createDeleteCancellationFeeInfoAction({
      ...actionFactoryParams,
    }),
    getCancellationFeeInfo: createGetCancellationFeeInfoAction({
      ...actionFactoryParams,
    }),
  };

  return actions;
};
